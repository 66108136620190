import React from "react";
import { Link } from "react-router-dom";
import "./Button.scss";

const Button = (props) => {
  const settings = props.block ? props.block.settings : null;

  if (settings) {
    if (settings.url?.includes("http")) {
      return (
        <a
          href={settings.url}
          className={"Button " + settings.class}
          id={settings.id}
          target="_blank"
          rel="noopener noreferrer"
        >
          {settings.text}
        </a>
      );
    } else {
      return (
        <Link
          to={settings.url}
          className={"Button " + settings.class}
          id={settings.id}
        >
          {settings.text}
        </Link>
      );
    }
  } else {
    if (props.url?.includes("http")) {
      return (
        <a
          href={props.url}
          className={"Button " + props.type}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.children}
        </a>
      );
    } else {
      return (
        <Link to={props.url} className={"Button " + props.type}>
          {props.children}
        </Link>
      );
    }
  }
};

export default Button;
