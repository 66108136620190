import React from "react";
import "./Text.scss";
import { DOMAIN } from "../../utils/apiData";

const Text = (props) => {
  const settings = props.block.settings;
  const workingHTML = settings.text?.replace('src="', 'src="' + DOMAIN);
  return (
    <div
      className={"Text " + settings.class}
      id={settings.id}
      dangerouslySetInnerHTML={{ __html: workingHTML }}
    ></div>
  );
};

export default Text;
