import React from "react";

const Input_select = (props) => {
  const settings = props.block.settings;

  const Dropdown = () => {
    if (props.block.children.length > 0) {
      return props.block.children.map((item, id) => (
        <option value={item.settings.value ? item.settings.value : ""} key={id}>
          {item.settings.label}
        </option>
      ));
    } else {
      return <option>No Options set.</option>;
    }
  };

  const RadioInput = (data) => {
    if (props.block.children.length > 0) {
      return props.block.children.map((item, id) => (
        <label htmlFor={item.settings.label} key={id}>
          <span className="label">{item.settings.label}</span>

          {data.required ? (
            <input
              type="radio"
              name={data.group}
              value={item.settings.value}
              required
            />
          ) : (
            <input type="radio" name={data.group} value={item.settings.value} />
          )}
        </label>
      ));
    } else {
      return <div>No Options set.</div>;
    }
  };

  switch (settings.type) {
    case "Radio Buttons":
      return (
        <div className={"formfield " + settings.name}>
          <label htmlFor={settings.name}>
            <span className="label">{settings.label}</span>
          </label>
          <RadioInput group={settings.name} required={settings.required} />
        </div>
      );

    case "Dropdown":
      return (
        <div className={"formfield " + settings.name}>
          <label htmlFor={settings.name}>
            <span className="label">{settings.label}</span>
          </label>
          {settings.required ? (
            <select
              name={settings.name}
              id={settings.id}
              className={settings.class}
              required
            >
              <Dropdown />
            </select>
          ) : (
            <select
              name={settings.name}
              id={settings.id}
              className={settings.class}
            >
              <Dropdown />
            </select>
          )}
        </div>
      );

    default:
      return (
        <div className={"formfield " + settings.name}>
          <div>No Options set.</div>
        </div>
      );
  }
};

export default Input_select;
