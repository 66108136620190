import React from "react";
import Picture from "../Picture/Picture";
import "./Teaser.scss";
import Button from "../Button/Button";

const Teaser = (props) => {
  const settings = props.block.settings;
  let path = settings.image.path;

  let alt = "";

  if (settings.image.meta) {
    alt = settings.image.meta.title;
  }

  return (
    <div className={"Teaser " + settings.class} id={settings.id}>
      <div className="image">
        <Picture src={path} alt={alt} />
      </div>
      <div className="headline">{settings.headline}</div>
      <div
        className="text"
        dangerouslySetInnerHTML={{ __html: settings.text }}
      ></div>
      <Button url={settings.buttonlink} type="invert">
        {settings.buttonlabel}
      </Button>
    </div>
  );
};

export default Teaser;
