import React from 'react';

const Divider = (props) => {
    const settings = props.block.settings;
  return (
    <hr 
      className={"Divider "+settings.class}
      id={settings.id}
    />
  );
};

export default Divider;

