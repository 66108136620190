import React, { useState } from "react";
import "./Accordion.scss";

const Accordion = (props) => {
  const settings = props?.block?.settings;
  const children = props?.block?.children;

  const AccordionItems = () => {
    return children.map((item, id) => (
      <AccordionItemContent item={item} key={id} />
    ));
  };

  const AccordionItemContent = (props) => {
    const [open, setOpen] = useState(false);
    if (props.item.component === "accordionItem") {
      return (
        <div className="AccordionItem">
          <b
            onClick={() => setOpen(!open)}
            className={`headline ${open ? "open" : "close"}`}
          >
            {props.item.settings.headline}
          </b>
          <div
            className={`itemContent ${open ? "open" : "close"}`}
            dangerouslySetInnerHTML={{ __html: props.item.settings.content }}
          ></div>
        </div>
      );
    } else {
      return "";
    }
  };

  return (
    <div className={`Accordion ${settings.class}`} id={settings.id}>
      <AccordionItems />
    </div>
  );
};

export default Accordion;
