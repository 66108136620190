import React from "react";

const Checkbox = (props) => {
  const settings = props.block.settings;

  const checkMe = (e) => {
    let elm = e.target;
    if (elm.checkValidity()) {
      console.log("valid");
    } else {
      console.log("INvalid");
    }
  };

  return (
    <div className={"formfield " + settings.name}>
      <label>
        <label htmlFor={settings.name}>
          {settings.required && (
            <input
              type="checkbox"
              name={settings.name}
              required
              onChange={checkMe}
            />
          )}
          {!settings.required && (
            <input type="checkbox" name={settings.name} onChange={checkMe} />
          )}
          {settings.label}
        </label>
      </label>
    </div>
  );
};
export default Checkbox;
