import React from "react";
import renderComponent from "../../utils/renderComponent";
import { APIURL, TOKEN } from "../../utils/apiData";

const PageElements = (props) => {
  const elementArr = props.block;

  const Elements = () => {
    if (elementArr.length > 0) {
      return elementArr.map((item, id) =>
        renderComponent(item, id, APIURL, TOKEN, props.cart)
      );
    } else {
      return renderComponent(elementArr, props.id, APIURL, TOKEN, props.cart);
    }
  };
  return (
    <Elements key={props.id} api={APIURL} token={TOKEN} cart={props.cart} />
  );
};

export default PageElements;
