import Image from "./Image/Image";
import Heading from "./Heading/Heading";
import Text from "./Text/Text";
import Button from "./Button/Button";
import Html from "./Html/Html";
import Gallery from "./Gallery/Gallery";
import Grid from "./Grid/Grid";
import Divider from "./Divider/Divider";
import Teaser from "./Teaser/Teaser";
import Section from "./Section/Section";
import Form from "./Form/Form";
import Input_text from "./Form/Input_text";
import Input_select from "./Form/Input_select";
import Datepicker from "./Form/Datepicker";
import Products from "./Products/Products";
import ProductDetail from "./Products/ProductDetail";
import Terminplaner from "./Terminplaner/Terminplaner";
import Checkbox from "./Form/Checkbox";
import Map from "./Map/Map";
import Video from "./Video/Video";
import Accordion from "./Accordion/Accordion";

export default {
  Image,
  Heading,
  Text,
  Button,
  Html,
  Gallery,
  Grid,
  Divider,
  Teaser,
  Section,
  Form,
  Input_text,
  Input_select,
  Datepicker,
  Products,
  ProductDetail,
  Terminplaner,
  Checkbox,
  Map,
  Video,
  Accordion,
};
