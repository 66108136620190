import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import subDays from "date-fns/subDays";
import getDay from "date-fns/getDay";
import format from "date-fns/format";
import eachDayOfInterval from "date-fns/eachDayOfInterval";

import { APIURL, TOKEN } from "../../utils/apiData";

import "react-datepicker/dist/react-datepicker.css";

const Datepicker = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [timeSlot, setTimeSlot] = useState("");
  const [termineArr, setTermineArr] = useState([]);
  const [allDates, setAllDates] = useState([]);
  const [exDates, setExDates] = useState();
  const [selectDate, setSelectDate] = useState(false);
  const settings = props.block.settings;

  registerLocale("de", de);

  const checkMe = (e) => {
    let elm = e.target;
    if (elm.checkValidity()) {
      console.log("valid");
    } else {
      console.log("INvalid");
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    const getDates = async () => {
      try {
        let database = settings.database;

        const result = await fetch(
          APIURL + "/api/collections/get/" + database + "?token=" + TOKEN,
          {
            method: "post",
            headers: { "Content-Type": "application/json" },
            signal: abortController.signal,
            body: JSON.stringify({
              fields: { date: 1, time: 1 },
              sort: { time: 1 },
            }),
          }
        );
        const data = await result.json();

        let test = [];
        data.entries.map((item) => {
          test.push(item.date);
          return null;
        });

        const result2 = {};
        for (let i = 0; i < test.length; i++) {
          result2[test[i]] = (result2[test[i]] || 0) + 1;
        }

        Object.keys(result2).map((key) => ({ [key]: result2[key] }));

        const getKeyByValue = (object, value) => {
          return Object.keys(object).find((key) => object[key] === value);
        };

        let dates = [];
        while (getKeyByValue(result2, settings.termine.length)) {
          let thisDate = getKeyByValue(result2, settings.termine.length);
          dates.push(new Date(thisDate));
          delete result2[thisDate];
        }

        let urlaub = [];
        if (settings.urlaubStart && settings.urlaubEnde) {
          urlaub = eachDayOfInterval({
            start: new Date(settings.urlaubStart),
            end: new Date(settings.urlaubEnde),
          });
        }
        let exDates = dates.concat(urlaub);

        setExDates(exDates);
        setAllDates(data);

        return data;
      } catch (error) {
        if (abortController.signal.aborted) {
          // cancelled
        } else throw error;
      }
    };
    if (settings.loadTimes || props.loadTimes) {
      getDates();
    }
    return () => abortController.abort();
  }, [
    settings.loadTimes,
    settings.database,
    props.loadTimes,
    settings.termine.length,
    settings.urlaubEnde,
    settings.urlaubStart,
  ]);

  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  const getFreeDates = (a1, a2) => {
    let diff = a1.filter((itm) => !a2.includes(itm));
    setTermineArr(diff);
  };

  const Termine = () => {
    if (termineArr.length > 0) {
      return termineArr.map((item, id) => (
        <div
          className={timeSlot === item ? "termin active" : "termin"}
          key={id}
          onClick={() => {
            setTimeSlot(item);
            if (props.termine) {
              props.termine(item);
            }
          }}
        >
          {item}
        </div>
      ));
    } else {
      return <div className="termin hide">No Timeslot set.</div>;
    }
  };

  const dateSelect = (date) => {
    setStartDate(date);
    setSelectDate(true);
    getTermineFromCollection(date);
    if (props.date) {
      date = format(date, "yyyy-MM-dd");
      props.date(date);
      if (props.update) {
        props.update();
      }
    }
  };

  const getTermineFromCollection = (date) => {
    if (date) {
      date = format(date, "yyyy-MM-dd");

      const termine = () => {
        let arr = [];
        settings.termine.map((items) => {
          arr.push(items.value);
          return null;
        });
        return arr;
      };

      const getSetDates = (date) => {
        let arr = [];
        allDates.entries.map((items) => {
          if (items.date === date) {
            arr.push(items.time);
          }
          return null;
        });
        return arr;
      };
      getFreeDates(termine(), getSetDates(date, allDates));
    }
  };

  return (
    <div className={"formfield " + settings.name}>
      <input type="hidden" name={settings.name} value="" />
      <label htmlFor={settings.name}>{settings.label}</label>
      {allDates.fields && (
        <DatePicker
          name={settings.name}
          locale="de"
          selected=""
          onChange={(date) => dateSelect(date)}
          inline
          disabledKeyboardNavigation
          filterDate={!settings.activateWeekend ? isWeekday : ""}
          excludeDates={exDates}
          dateFormat="yyyy-MM-dd"
          minDate={subDays(new Date(), 0)}
        />
      )}

      <input
        type="hidden"
        name="date"
        value={format(startDate, "yyyy-MM-dd")}
        onChange={checkMe}
      />
      <div className="terminliste">
        {selectDate && (
          <span>
            Bitte Uhrzeit wählen:
            <br />
          </span>
        )}
        <Termine key={termineArr} />
      </div>

      <input type="hidden" name="time" value={timeSlot} onChange={checkMe} />
    </div>
  );
};

export default Datepicker;
