import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "./Map.scss";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css"; // Re-uses images from ~leaflet package
import "leaflet-defaulticon-compatibility";

const Map = (props) => {
  const settings = props.block.settings;
  const coords = settings.koordinaten;

  return (
    <div className="Map">
      <MapContainer
        center={[coords.lat, coords.lng]}
        zoom={16}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[coords.lat, coords.lng]}>
          <Popup>
            Kirchgasse 8<br />
            65185 Wiesbaden
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};
export default Map;
