import React from "react";
import "./Video.scss";
import { APIURL } from "../../utils/apiData";

const Video = (props) => {
  const settings = props.block.settings;
  let videoSettings = {};

  if (settings.autoplay) {
    videoSettings["autoPlay"] = "autoPlay";
  }
  if (settings.muted) {
    videoSettings["muted"] = "muted";
  }
  if (settings.loop) {
    videoSettings["loop"] = "loop";
  }
  if (settings.controls) {
    videoSettings["controls"] = "controls";
  }

  return (
    <div className={`Video ${settings.class}`} id={settings.id}>
      {settings.filename.video && (
        <video {...videoSettings} playsInline>
          <source
            src={APIURL + "/storage/uploads" + settings.filename.path}
            type={settings.filename.path.mime}
          />
        </video>
      )}
      {!settings.filename.video && (
        <div>Es wurde keine Video-Datei angegeben.</div>
      )}
    </div>
  );
};

export default Video;
