import React from 'react';
import Picture from '../Picture/Picture';
import { DOMAIN } from '../../utils/apiData';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

import Masonry from 'react-masonry-css'

import './Gallery.scss';

const Gallery = (props) => {

    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1
      };

    const settings = props.block.settings;
    if(props.block.settings.gallery){
        // const elementArr = props.block.settings.gallery;
        // const GalleryElement = () =>{
        //     if(elementArr.length > 0){
        //         return elementArr.map((item, index) => (          
        //             <div className="GalleryImage" key={index} >
        //                 <Picture src={item.path} alt={item.meta.title} />
        //                 <span className="title">{item.meta.title}</span>
        //             </div>
        //         ))
        //     }else{
        //         return ( 
        //             <span>Keine Bilder vorhanden</span>
        //         )
        //     }
        // }

        let elementArr = props?.block?.settings?.gallery;

        elementArr = elementArr.map(function(item, index) {
            return (          
                            <div className="GalleryImage" key={index} >
                                <a href={DOMAIN + item.path}><Picture src={item.path} alt={item.meta.title} /></a>
                                <span className="title">{item.meta.title}</span>
                            </div>
                        )
          });

        return (
            <SimpleReactLightbox>            
                <div className={"Gallery "+settings.class} id={settings.id}>
                    <SRLWrapper>
                        <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                            {/* <GalleryElement /> */}
                            {elementArr}
                        </Masonry>
                    </SRLWrapper>
                </div>
            </SimpleReactLightbox>
        );
    }else{
        return (
            
                <div className={"Gallery "+settings.class}
                id={settings.id}>
                No Gallery set!
                </div>
            
          );
    }
};

export default Gallery;
