import React from 'react';
import PageElements from "../PageElements/PageElements"

const Section = (props) => {
  const settings = props.block.settings;

  if(props.block.children.length > 0){
    const columnsArr = props.block.children;
    
    const Element = () => {
        if(columnsArr.length > 0){
            return columnsArr.map((item, id) => (      
                <PageElements block={item} key={id}/>
          ))
        }
    }
  return (
    <section className={"Section "+settings.class}
            id={settings.id}>
        <Element />
    </section>
  );
  }else{
    return (
      <div className={"Section "+settings.class}
      id={settings.id}>
         No Section set!
      </div>
    );    
  }
};

export default Section;
