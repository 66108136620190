import React, { useState, useEffect, useContext } from "react";
import Picture from "../Picture/Picture";
import { Link, useLocation } from "react-router-dom";
import "./ShoppingCartPage.scss";
import { ShopSettings } from "../../Page";

import Delete from "../../statics/img/delete.svg";

const ShoppingCartPage = (props) => {
  const thisSettings = useContext(ShopSettings);
  const [state, setState] = useState({
    cart: [],
    products: [],
    price: 0,
    settings: false,
    ready: false,
  });
  document.currentPrice = parseFloat("0").toFixed(2);
  let location = useLocation();

  useEffect(() => {
    const abortController = new AbortController();
    let cart = [];

    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));
      setState((prev) => ({
        ...prev,
        cart: cart,
      }));
    }

    if (thisSettings) {
      setState((prev) => ({
        ...prev,
        settings: thisSettings,
        ready: true,
      }));
    }

    return () => abortController.abort();
  }, [location, thisSettings]);

  const deleteFromCart = (number) => {
    localStorage.removeItem(number);

    const array = state.cart;

    const index = array.indexOf(number);
    if (index > -1) {
      array.splice(index, 1);
      setState((prev) => ({
        ...prev,
        cart: array,
      }));
      localStorage.setItem("cart", JSON.stringify(array));
      props.cart();
    }
  };

  const getItemFromLS = (item, key) => {
    let cartItem = JSON.parse(localStorage.getItem(item));
    document.currentPrice =
      document.currentPrice + parseFloat(cartItem.price).toFixed(2);

    return (
      <li key={key}>
        <img
          src={Delete}
          onClick={() => deleteFromCart(cartItem.number)}
          className="del"
          alt="aus dem Warenkorb löschen"
        />
        <div>
          <Picture src={cartItem.pictures[0].path} alt={cartItem.name} />
        </div>
        <div className="details">
          <div className="name">
            <Link
              to={
                "/produkt-detail/" + cartItem.name_slug + "/" + cartItem.number
              }
            >
              {cartItem.name}
            </Link>
          </div>
          <div className="number">{cartItem.number}</div>
          <div className="price">
            {parseFloat(cartItem.price).toFixed(2) + " €"}
          </div>
        </div>
      </li>
    );
  };

  const CartContent = () => {
    if (state.cart.length > 0) {
      return (
        <ul className="ShoppingCartPage">
          {state.cart.map((item, key) => getItemFromLS(item, key))}
        </ul>
      );
    } else {
      return (
        <div className="empty">
          Der Warenkorb ist leer
          <Link to="/produkte" className="Button invert">
            Jetzt weiter shoppen
          </Link>
        </div>
      );
    }
  };

  const CartPrice = () => {
    let gesamt = 0;
    if (state.cart.length > 0) {
      let price = parseFloat(0).toFixed(2);
      state.cart.map((item) => {
        let cartItem = JSON.parse(localStorage.getItem(item));
        price = parseFloat(price) + parseFloat(cartItem.price);
        return null;
      });
      if (state.settings.shipping) {
        gesamt = price + parseFloat(state.settings.shipping);
      }

      return (
        <div className="ShoppingCartPrice">
          <div className="headline">Zusammenfassung</div>
          <div className="price">
            <span>Zwischensumme:</span> <span>{price.toFixed(2)} €</span>
          </div>
          <div className="price">
            <span>Versandkosten:</span>{" "}
            <span>
              {state.settings && parseFloat(state.settings.shipping).toFixed(2)}{" "}
              €
            </span>
          </div>
          <div className="price">
            <span>Gesamt:</span> <span>{gesamt.toFixed(2)} €</span>
          </div>
          <Link className="Button invert" to="/checkout">
            Zur Kasse gehen
          </Link>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <section className="Section cart">
      <CartContent />
      {state.ready && <CartPrice />}
    </section>
  );
};

export default ShoppingCartPage;
